
var form = document.querySelector("form.stepped-survey");
var slideNextButtons = document.querySelectorAll(
  '.step input[type="radio"], .step button.next-step'
);
var slideFields = document.querySelectorAll(".step input, .step select");
var standaloneSelects = document.querySelectorAll(".step .block.select select");
var slidePreviousButton = document.querySelector(
  ".stepped-survey button.previous"
);
var slideshowStepsWrapper = document.querySelector('.stepped-survey .steps');
var phoneFields = document.querySelectorAll(".phone");
var errorMessage = document.getElementById("errorMessage");
var query = false;
if (queryParams && typeof queryParams === "function") query = queryParams();

function triggerErrorMessage(message) {
  var text = errorMessage.querySelector(".notification-text");
  if (text) text.innerHTML = message || "";
  errorMessage.classList.add("active");
  setTimeout(function() {
    errorMessage.classList.remove("active");
  }, 2000);
}

function nextSlide(event) {
  window.scrollTo(0, 0);
  var field = event.target;
  var activeSlide = document.querySelector(".step.active");
  var nextSlide = document.querySelector(".step.next");
  var previousSlide = document.querySelector(".step.previous");
  if (activeSlide) {
    var requiredFields = activeSlide.querySelectorAll(
      "input[required], select[required]"
    );
    if (requiredFields && requiredFields.length > 0) {
      var errorField = [].some.call(requiredFields, function(requiredField) {
        if (requiredField.value === "") {
          requiredField.focus();
          triggerErrorMessage("Please choose an answer.");
          return true;
        }
        if (requiredField === field && field.checked && field.dataset.message) {
          triggerErrorMessage(field.dataset.message);
          return true;
        }
      });
      if (errorField) return false;
    }
  }
  if (nextSlide) {
    var stepName = activeSlide.dataset.name;
    var step = activeSlide.dataset.step;
    var nextStepName = nextSlide.dataset.name;
    var nextStep = nextSlide.dataset.step;
    if (gtag) {
      gtag("event", "answered", {
        event_category: stepName,
        event_label: "step_" + step
      });
      gtag("event", "viewed", {
        event_category: nextStepName,
        event_label: "step_" + nextStep,
        non_interaction: true
      });
    }
    if (window.real) {
      real.click({
        question: stepName,
        sequence: step,
        answer: "answered",
        email: !query ? "no-user" : query.gclid || "no-user",
        trafficSource: !query ? "direct" : query.utm_source || "direct",
        isOptin: false
      });
      real.impression({
        question: nextStepName,
        sequence: nextStep,
        email: !query ? "no-user" : query.gclid || "no-user",
        trafficSource: !query ? "direct" : query.utm_source || "direct"
      });
    }
    if (activeSlide) activeSlide.classList.add("movingLeft");
    if (nextSlide) nextSlide.classList.add("movingLeft");
    if (previousSlide) previousSlide.classList.remove("previous");
    setTimeout(function() {
      activeSlide.classList.remove("movingLeft");
      activeSlide.classList.add("previous");
      activeSlide.classList.remove("active");
      nextSlide.classList.remove("movingLeft");
      nextSlide.classList.add("active");
      nextSlide.classList.remove("next");
      form.dataset.step = [].indexOf.call(
        nextSlide.parentNode.children,
        nextSlide
      );
      var input = nextSlide.querySelector(".inputs input, .inputs select");
      if (input) input.focus();
      if (nextSlide.nextElementSibling) {
        nextSlide.nextElementSibling.classList.add("next");
      }
    }, 800);
  }
}
function previousSlide(event) {
  window.scrollTo(0, 0);
  slidePreviousButton.disabled = true;
  var activeSlide = document.querySelector(".step.active");
  var nextSlide = document.querySelector(".step.next");
  var previousSlide = document.querySelector(".step.previous");
  if (previousSlide) {
    if (activeSlide) activeSlide.classList.add("movingRight");
    if (nextSlide) nextSlide.classList.remove("next");
    if (previousSlide) previousSlide.classList.add("movingRight");
    setTimeout(function() {
      activeSlide.classList.remove("movingRight");
      activeSlide.classList.remove("active");
      activeSlide.classList.add("next");
      previousSlide.classList.remove("movingRight");
      previousSlide.classList.remove("previous");
      previousSlide.classList.add("active");
      form.dataset.step = [].indexOf.call(
        previousSlide.parentNode.children,
        previousSlide
      );
      var input = previousSlide.querySelector(".inputs input, .inputs select");
      if (input) input.focus();
      slidePreviousButton.disabled = false;
      if (previousSlide.previousElementSibling) {
        previousSlide.previousElementSibling.classList.add("previous");
      }
    }, 800);
  }
}
function closest(element, callback) {
  return (
    element &&
    (callback(element) ? element : closest(element.parentNode, callback))
  );
}
function handleFocusBlur(event) {
  var focusedField = event.target;
  var activeSlide = document.querySelector(".step.active");
  var activeSlideFields = activeSlide.querySelectorAll("input, select");
  var focusedFieldSlide = closest(focusedField, function(element) {
    return element.classList.contains("step");
  });
  var firstField = activeSlide.querySelector(
    "input:first-of-type, select:first-of-type"
  );
  var lastField = activeSlide.querySelector(
    "input:last-of-type, select:last-of-type"
  );
  if (!focusedFieldSlide) {
    firstField.focus();
    event.preventDefault();
    event.stopPropagation();
  } else if (focusedFieldSlide !== activeSlide) {
    event.preventDefault();
    event.stopPropagation();
    switch (true) {
      case focusedFieldSlide.classList.contains("next"):
        if (activeSlideFields.length > 1) {
          firstField.focus();
        } else {
          lastField.focus();
        }
        break;
      case focusedFieldSlide.classList.contains("previous"):
        if (firstField.name === "birth_month") {
          firstField.focus();
        }
        if (activeSlideFields.length > 1) {
          lastField.focus();
        } else {
          firstField.focus();
        }
        break;
      case focusedFieldSlide.classList.contains("active"):
      default:
        firstField.focus();
    }
    slideshowStepsWrapper.scrollLeft = 0;
  }
}

[].forEach.call(slideFields, function(slideField) {
  slideField.addEventListener("focus", handleFocusBlur);
});
[].forEach.call(slideNextButtons, function(slideNextButton) {
  slideNextButton.addEventListener("click", nextSlide);
});
[].forEach.call(standaloneSelects, function(standaloneSelect) {
  standaloneSelect.addEventListener("change", nextSlide);
});
slidePreviousButton.addEventListener("click", previousSlide);
form.addEventListener("keydown", function(e) {
  var activeSlide = document.querySelector(".step.active");
  if (!activeSlide.classList.contains("final") && e.keyCode === 13) {
    e.preventDefault();
    nextSlide();
  }
});
form.addEventListener("submit", function(e) {
  e.preventDefault();
  var activeSlide = document.querySelector(".step.active");
  var stepName = activeSlide.dataset.name;
  var step = activeSlide.dataset.step;
  var smsOptIn = document.getElementById("sms_opt_in");
  if (gtag) {
    gtag("event", "answered", {
      event_category: activeSlide.dataset.name,
      event_label: "step_" + step
    });
    gtag("event", "enrolled", {
      event_category: "user"
    });
    if (smsOptIn && smsOptIn.checked) {
      gtag("event", "enrolled-tcpa", {
        event_category: "user"
      });
    }
  }
  if (window.real) {
    real.click({
      question: stepName,
      sequence: step,
      answer: "answered",
      email: !query ? "no-user" : query.gclid || "no-user",
      trafficSource: !query ? "direct" : query.utm_source || "direct",
      isOptin: false
    });
  }
  var search = window.location.search;
  if (search) {
    if (!form.actionOrig) {
      form.actionOrig = form.action;
    }
    form.action = form.actionOrig + search;
  }
  form.submit();
});

function numbersOnly(event) {
  var keyCode = event.keyCode;
  if (
    [
      8,
      9,
      16,
      37,
      38,
      39,
      40,
      46,
      96,
      97,
      98,
      99,
      100,
      101,
      102,
      103,
      104,
      105
    ].indexOf(keyCode) > -1
  )
    return true;
  if (keyCode > 33 && (keyCode < 48 || keyCode > 57)) {
    if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !event.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }
}

var autofillFired = false;
function markBlurred(event) {
  var field = event.target;
  if(!autofillFired) {
    if(document.querySelectorAll('input:-webkit-autofill').length>0){
      autofillFired = true;
      if (gtag) {
        gtag("event", "AUTOFILL", {
          event_category: 'Autofill Used',
          event_label: "User used Autofill to fill out form."
        });
      }
    }
  }

  if (field.value.length > 0 && !field.classList.contains("blurred"))
    field.classList.add("blurred");
}

function progressNextField(event) {
	var next_sibling  = event.target.nextElementSibling.nodeName;
  var field = event.target;
  if ([8, 9, 16, 37, 38, 39, 40, 46].indexOf(event.keyCode) > -1) return true;
  if (field.value.length === field.maxLength) {
    if(next_sibling == 'SPAN') {
      field.nextElementSibling.nextElementSibling.focus();
    }
    else {
      field.nextElementSibling.focus();
    }
  }
}
[].forEach.call(phoneFields, function(phoneField) {
  var area = phoneField.querySelector(".area");
  var prefix = phoneField.querySelector(".prefix");
  var lineNumber = phoneField.querySelector(".lineNumber");
  var hiddenField = phoneField.querySelector(".hidden-field");
  area.addEventListener("blur", markBlurred);
  prefix.addEventListener("blur", markBlurred);
  area.addEventListener("keyup", progressNextField);
  prefix.addEventListener("keyup", progressNextField);
  area.addEventListener("keydown", numbersOnly);
  prefix.addEventListener("keydown", numbersOnly);
  lineNumber.addEventListener("keydown", numbersOnly);
});

function queryParams(searchString) {
  var search = searchString || window.location.search || "";
  if (search) {
    var queryObject = {};
    var searchArray = search.replace(/^\?/, "").split("&");
    searchArray.forEach(function(data) {
      var keyValue = data.split(/\=(.+)/);
      queryObject[keyValue[0]] = keyValue[1] || "";
    });
    return queryObject;
  } else {
    return false;
  }
}

window.addEventListener("load", function() {
  var queryDiv = document.getElementById("queryParams");

  // for (var prop in query) {
  //   if(document.querySelector('[name=' + prop + ']') ==  undefined) {
  //     var hiddenField = document.createElement("input");
  //     hiddenField.type = "hidden";
  //     hiddenField.name = prop;
  //     hiddenField.value = query[prop];
  //     queryDiv.appendChild(hiddenField);
  //   } else {
  //     document.querySelector('[name=' + prop + ']').value = query[prop];
  //   }
  // }

  var full_name = document.getElementById("full_name");
  if (full_name) {
    var first_name = document.getElementById("first_name");
    var last_name = document.getElementById("last_name");
    full_name.addEventListener("change", function() {
      var names = full_name.value.split(" ");
      first_name.value = "";
      last_name.value = "";
      for (var i = 0; i < names.length; i++) {
        if (i < names.length / 2) first_name.value += names[i] + " ";
        else last_name.value += names[i] + " ";
      }

      first_name.value = first_name.value.trim();
      last_name.value = last_name.value ? last_name.value.trim() : "-";
    });

    var firstNameStr = query['fname'] || query['first_name'] || '';
    var lastNameStr = query['lname'] || query['last_name'] || '';
    var fullnameString = firstNameStr + ' ' + lastNameStr;

    full_name.value = fullnameString;
    full_name.dispatchEvent(new Event('change'));
  }

  var marketingPartnersTrigger = document.getElementById(
    "marketingPartners-trigger"
  );
  var marketingPartnersWrapper = document.getElementById(
    "marketingPartners-wrapper"
  );
  var marketingPartnersContent = marketingPartnersWrapper.querySelector(
    "#marketingPartners-partners"
  );
  var marketingPartnersClose = marketingPartnersWrapper.querySelector(
    "#marketingPartners-close"
  );

  var xmlhttp = new XMLHttpRequest();
  xmlhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      var data = JSON.parse(this.responseText);
      marketingPartnersContent.innerHTML = data.records[0].fields[0].value;
    }
  };

  xmlhttp.open("GET", window.marketingPartnersURL + '?f=1' , true);
  xmlhttp.send();
  function toggleMarketingPartners(event) {
    event.preventDefault();
    event.stopPropagation();
    marketingPartnersWrapper.classList.toggle("active");
  }
  if (marketingPartnersTrigger)
    marketingPartnersTrigger.addEventListener("click", toggleMarketingPartners);
  if (marketingPartnersClose)
    marketingPartnersClose.addEventListener("click", toggleMarketingPartners);

  var emailField = document.getElementById("email");
  emailField.addEventListener("change", function(event) {
    var emailValidation = document.getElementById("email-validation");
    var messageArea = emailValidation
      ? emailValidation.querySelector(".message")
      : false;
    if (!emailValidation.classList.contains("changed")) {
      emailValidation.classList.add("changed");
    }
    emailValidation.classList.add("loading");
    if (event.target.value === "") {
      messageArea.innerHTML = "Email address cannot be empty.";
      emailValidation.classList.remove("loading");
      return false;
    }
    validateEmail(event.target.value, form.dataset.authorization, function(
      result
    ) {
      emailValidation.classList.remove("loading");
      if (messageArea) {
        messageArea.innerHTML =
          "Email address is " + (result ? "valid" : "invalid") + ".";
      }
    });
  });


  var phone_num = query['phone'] || '' ;
  var p1 = phone_num.slice(0,3);
  var p2 = phone_num.slice(3,6);
  var p3 = phone_num.slice(6);
  emailField.value=query['email'] || '';
  if (query['email'] !== '' && query['email'] !== undefined) {
    emailField.dispatchEvent(new Event('change'));
  }
  phoneFields[0].querySelector('.area').value=p1;
  phoneFields[0].querySelector(".prefix").value=p2;
  phoneFields[0].querySelector(".lineNumber").value=p3;
});

function validateEmail(email, authorization, callback) {
  if (!email || !authorization) return false;
  var xmlhttp = new XMLHttpRequest();
  var url = (window.DOMAIN || "") + "/verify-email?email=" + email;
  xmlhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      var data = null;
      try {
        data = this.responseText ? JSON.parse(this.responseText) : "";
      } catch (error) {}
      if (data && data.result && data.result === "valid") {
        callback(true);
        return true;
      }
      callback(false);
      return false;
    }
  };
  xmlhttp.open("POST", url, true);
  xmlhttp.setRequestHeader("Authorization", authorization);
  xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xmlhttp.send();
}

if (window.shortFormEventsEnabled) {
  var phoneAreaCode = phoneFields[0].querySelector('.area');
  var phonePrefix = phoneFields[0].querySelector(".prefix");
  var phoneLineNumber = phoneFields[0].querySelector(".lineNumber");
  var smsOptIn = document.getElementById("sms_opt_in");
  var optIn = document.getElementById("opt_in");

  email.addEventListener("blur", function(e) {
    fireGtagEvent('fillout-name', e.target.value);
  });
  full_name.addEventListener("blur", function(e) {
    fireGtagEvent('fillout-email', e.target.value);
  });


  phoneAreaCode.addEventListener("blur", function(e) {
    fireGtagEvent('fillout-phonenumber:areacode', e.target.value);
  });
  phonePrefix.addEventListener("blur", function(e) {
    fireGtagEvent('fillout-phonenumber:prefix', e.target.value);
  });
  phoneLineNumber.addEventListener("blur", function(e) {
    fireGtagEvent('fillout-phonenumber:lineNumber', e.target.value);
  });

  smsOptIn.addEventListener('change', function(e) {
    fireGtagEvent('optin-tcpa', e.target.value);
  });
  optIn.addEventListener('change', function(e) {
    fireGtagEvent('optin-terms', e.target.value);
  });
}

function fireGtagEvent(event, value) {
  if (value !== '') {
    gtag('event', event, {
      'event_category': 'user',
      'value': value
    });
  }
}
